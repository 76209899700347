import React from 'react';
import { Grid, Link, NavList, Title, GovBanner, Header as USHeader } from "@trussworks/react-uswds";
import SBALogo from '../../images/SBA-Logo-Horizontal.png';
import './header.css'

const sbaWebsiteUrl = 'https://www.sba.gov';

const Header = ({title}) => {

    const navLinks = [
        // <Link href='/' target={'_self'} title='About HUBZone'>About</Link>,
    ]

    return (
        <React.Fragment>
            <GovBanner id='header-gov-banner-container' aria-label="Official Government Website" />
            <Grid row gap='md' id='header-grid'>
                <Grid id='header-logo-container' col='auto'>
                    <a href={sbaWebsiteUrl} className="sbaLink">
                        <img
                            resize="true"
                            src={SBALogo}
                            alt="Small Business Administration"
                            className="logo-"
                            height={56}
                            width={206}
                        />
                    </a>
                </Grid>
                <Grid id='header-main-container' col='fill'>
                    <USHeader>
                        <Title>Small Business Data HUB</Title>
                        {/* <Title>{`Certify ${(title ? title : '')+' '}Dashboards`}</Title> */}
                    </USHeader>
                </Grid>
                <Grid id='header-links-container' col='auto'>
                    <NavList items={navLinks} type={'primary'}/>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default Header;