import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import Main from './pages/layouts/main/main.layout';
import ReactGA from 'react-ga';

function App() {
  // Initialize Google Analytics using the tracking ID from environment variables called DATAHUB_TRACKING_ID
  ReactGA.initialize(process.env.REACT_APP_DATAHUB_TRACKING_ID || 'UA-000000-01');

  const [data, setData] = useState();

  const oppl_dashboard = process.env.REACT_APP_OPPL_DASHBOARD || 'localhost:8501'

  useEffect(() => {
    const getData = async () => {
      await fetch('http://127.0.0.1:8000/')
        .then(resp => resp.json())
        .then(data => {
          setData(data);
        })
    }

    // getData();
  }, []) // Run when component mounts

  return (
    <Main title={data && data.message}>
      <div className="App">
        <header className="App-header">
          <div>
            {/* <h1>{data && data.message}</h1> */}
            <iframe src={`${oppl_dashboard}`}></iframe>
          </div>
        </header>
      </div>
    </Main>
  );
}

export default App;
