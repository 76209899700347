
import React from 'react';
import PropTypes from 'prop-types';
import { GridContainer } from '@trussworks/react-uswds';
import Header from "../../header/header";
import Footer from '../../footer/footer';

const Main = ({children, title=null}) => {
    return (
        <React.Fragment>
            <Header title={title}/>
            <GridContainer id="layout-grid">
                <main key={`main`} id="calculator-main"> 
                {children}          
                </main>
            </GridContainer>
            <Footer />
        </React.Fragment>
    )
}

Main.prototype = {
    children: PropTypes.node.isRequired
}

export default Main;