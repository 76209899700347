import React from 'react';
import { Footer as UsaFooter, FooterNav, Grid, Address } from '@trussworks/react-uswds';

import SBALogo from '../../images/SBA-Logo-Horizontal.png';



import './footer.css';

const sbaWebsiteUrl = 'https://www.sba.gov';

const Footer = () => {

    const primary = () => {
        return (
            <React.Fragment>
                <Grid id="footer-primary" row>
                    {/* <FooterNav size='slim'
                        links={Array(2).fill(
                            <a className="usa-footer__primary-link" href="#">
                                Primary Link
                            </a>
                        )}
                    /> */}
                    <Address
                        size="slim"
                        items={[
                            // <a key="email" href="mailto:HUBZone@sba.gov">
                            //     HUBZone@sba.gov
                            // </a>,
                        ]}
                    />
                </Grid>
            </React.Fragment>
        )
    }

    const secondary = () => {
        return (
            <React.Fragment>
                <Grid id="footer-secondary" row>
                    <a href={sbaWebsiteUrl} className="sbaLink">
                        <img
                            resize="true"
                            src={SBALogo}
                            alt="Small Business Administration"
                            className="logo-"
                            height={80}
                        />
                    </a>
                </Grid>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <UsaFooter size='slim' primary={primary()} secondary={secondary()} />
        </React.Fragment>

    )
}

export default Footer;